<template>
    <div>
        <div @click="selectFolder(folder.id)" :style="isSelected ? 'font-weight: bold' : 'font-weight: normal'" style="cursor:pointer; margin-top: 5px; margin-bottom: 5px;" >
            <i :class="[ isOpen ? 'fa-folder-open' : 'fa-folder' ]" class="fa fa-sm" :style="indent"></i> <span class="tree-text">{{folder.name}}</span>
        </div>
        <div v-if="isOpen" v-for="child in folder.children">
            <FolderTree :folder="child" :level="level + 1" @folderSelection="subFolderSelected"></FolderTree>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'FolderTree',
        props: {
            folders: {},
            folder: {},
            level: 0,

        },
        data() {
            return {
                isOpen: false,
                isSelected: false
            }
        },
        computed: {
            indent() {
                return { 'margin-left': 6 * this.level + 'px' }
            }
        },
        methods: {
            selectFolder: function (id) {
                for(let j = 0; j < this.$children.length; j++){
                    if(this.$children[j].$options.name == 'FolderTree'){
                        this.$children[j].resetSelection()
                    }
                }

                if(this.$parent.$options.name == 'FolderTree'){
                    for(let i = 0; i < this.$parent.$children.length; i++) {
                        if(this.$parent.$children[i].$options.name == 'FolderTree'){
                            this.$parent.$children[i].resetSelection()
                        }
                    }
                }

                this.isOpen = !this.isOpen
                this.isSelected = this.folder.id == id
                this.$emit('folderSelection', this.folder, this)
            },
            subFolderSelected(folder) {
                this.isSelected = this.folder.id == folder.id
                this.$emit('folderSelection', folder, this)
            },
            resetSelection(){
                this.isOpen = false
                this.isSelected = false
            }
        }
    }

</script>