<template>
  <div id="EditPoi">
    <b-row>
      <b-col sm="8">
        <div class="card">
          <div slot="header" class="card-header">
            <i class="fa fa-edit"></i> Kategorie bearbeiten
          </div>
          <div v-if="category" class="card-body">
            <b-form v-on:submit.prevent="onSubmit">
              <b-form-group validated>
                <label for="name">Name</label>
                <b-form-input type="text" id="name" required aria-required="true" v-model="category.name"
                              placeholder="Kategoriename"></b-form-input>
              </b-form-group>
              <b-form-group>
                <label>Bild</label><br>
                <div class="margin-bottom-10">
                  <img v-if="category.image && category.image.id > 0"
                       v-bind:src="$store.state.mediaServiceUrl + category.image.uid + '/200/200'"
                       style="width: auto; height: auto; max-width: 200px; max-height: 200px;">
                </div>
                <b-button size="sm" @click="openMediaSelection('image')" variant="dark"><i class="fa"></i>Bild
                  auswählen/ändern
                </b-button>&nbsp;
                <a class="fa fa-trash fa-lg text-primary" v-if="category.image && category.image.id"
                   @click="deleteMedia('image')"></a>
              </b-form-group>
              <b-form-group>
                <label>LOGO-Bild</label><br>
                <div class="margin-bottom-10">
                  <img v-if="category.logoImage && category.logoImage.id > 0"
                       v-bind:src="$store.state.mediaServiceUrl + category.logoImage.uid + '/200/200'"
                       style="width: auto; height: auto; max-width: 200px; max-height: 200px;">
                </div>
                <b-button size="sm" @click="openMediaSelection('logoImage')" variant="dark"><i class="fa"></i>Bild
                  auswählen/ändern
                </b-button>&nbsp;
                <a class="fa fa-trash fa-lg text-primary" v-if="category.logoImage && category.logoImage.id"
                   @click="deleteMedia('logoImage')"></a>
              </b-form-group>
              <br>
              <div class="footer-btn" slot="footer">
                <b-button type="submit" size="sm" variant="primary"><i class="fa fa-save"></i> Speichern</b-button>
                <b-button size="sm" href="/#/property/categories/" variant="danger"><i class="fa fa-ban"></i>Abbrechen
                </b-button>
              </div>
            </b-form>
          </div>
          <br>
        </div>
      </b-col>
      <MediaSelection
        :show="showMediaSelection"
        :type="mediaType"
        :mime-types="'image/jpeg,image/png'"
        @close="showMediaSelection = false"
        @save="saveMedia($event)"
      />
    </b-row>
  </div>
</template>

<script>

  import MediaSelection from '../Media/MediaSelection'
  import {mapGetters} from 'vuex'
  import VueNotifications from 'vue-notifications'

  const axios = require('axios')

  export default {
    components: {
      MediaSelection,
    },
    name: 'editCategory',
    data() {
      return {
        showMediaSelection: false,
        mediaType: '',
      }
    },
    computed: {
      ...mapGetters({
        category: 'poi/category/getCategory',
        media: 'media/getMedia',
        error: 'poi/category/getError'
      })
    },
    mounted() {
      if (this.$route.params.id > 0) {
        this.$store.dispatch('poi/category/loadCategory', this.$route.params.id)
      } else {
        this.$store.dispatch('poi/category/initCategory')
      }
    },
    methods: {
      openMediaSelection: function (mediaType) {
        this.showMediaSelection = true
        this.mediaType = mediaType
      },
      saveMedia: function (payload) {
        this.category[this.mediaType] = {'id': payload.id, 'uid': payload.uid}
      },
      deleteMedia: function (mediaType) {
        this.category.image = undefined;
      },
      onSubmit() {
        this.$store.dispatch('poi/category/saveCategory', {
          id: this.$route.params.id,
          name: this.category.name,
          image: this.category.image ? this.category.image.id : '',
          logoImage: this.category.logoImage ? this.category.logoImage.id : '',
        }).then(response => {
          this.notifyTemplate({
            type: VueNotifications.types.success,
            title: 'Gaststättenart wurde erfolgreich gespeichert'
          })
          this.$router.replace(this.$route.query.redirect || '/property/categories')
        }, error => {
          this.notifyTemplate({
            type: VueNotifications.types.error,
            title: 'Fehler beim Speichern: ' + error
          })
        })
      }
    },
    notifications: {
      notifyTemplate: {},
    }
  }
</script>
