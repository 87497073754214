<template>
    <b-col v-bind:sm="!block? 4: 12" v-if="show" :style="`margin-top: ${screenPosition}px`">
        <div data="show"  class="card" id="mediaSelection">
            <div slot="header" class="card-header">
                <i class="fa fa-image"></i> {{ title }}
                <button type="button" @click="closeMediaSelection()" class="btn btn-danger btn-sm" style="float:right"><span class="icon-close"></span></button>
                <button type="button" class="btn btn-primary btn-sm float-right" @click="openFileUploadDialog()">
                    <span class="icon-plus"></span> Medien hinzufügen
                </button>
            </div>
            <b-row>
                <b-col>
                    <div v-if="folders" style="margin-top: 20px; margin-left: 10px; padding-left: 5px; margin-right: 10px;" class="card">
                        <div v-for="folder in folders">
                            <FolderTree :folder="folder" :level="0" @folderSelection="folderSelection"></FolderTree>
                        </div>
                    </div>
                </b-col>

            </b-row>

            <div v-if="media" class="card-body">
                <div v-for="medi in media">
                    <div v-if="mimeTypes.indexOf(medi.mimeType) > -1" @click="selectMedia(medi)" class="card" :class="[ selectedId > 0 && medi.id === selectedId ? 'border-primary' : '' ]" style="width: 5.5rem; float: left; margin-right: 20px; cursor:pointer; text-align: center">
                        <div style="position: relative; heigth: 0; padding-bottom: 66.5%;">
                            <img v-if="medi.mimeType.includes('image')" class="card-img-top" style="width: auto; height: auto; max-width: calc(100% - 10px); max-height: 90%; position:absolute; left: 50%; top:50%; transform: translate3d(-50%,-50%, 0);" :title="medi.name"  v-bind:src="$store.state.mediaServiceUrl + medi.uid + '/200/200'" v-bind:alt="medi.alt">
                            <div v-if="medi.mimeType.includes('video')">
<!--                                <div style="float: left;">-->
<!--                                    <img v-if="medi.mimeType.includes('youtube')" class="card-img-top" style="width: auto; height: auto; max-width: calc(100% - 10px); max-height: 90%; position:absolute; left: 50%; top:50%; transform: translate3d(-50%,-50%, 0);" :title="medi.name" src="img/icons/youtube.png" v-bind:alt="medi.alt">-->
<!--                                    <img v-else class="card-img-top" style="width: auto; height: auto; max-width: calc(100% - 10px); max-height: 90%; position:absolute; left: 50%; top:50%; transform: translate3d(-50%,-50%, 0);" :title="medi.name" v-bind:src="medi.image? $store.state.mediaServiceUrl + medi.image.uid + '/200/200' : 'img/icons/multimedia.png'" v-bind:alt="medi.alt">-->
<!--                                </div>-->
                                <div style="position: absolute">
                                    <p v-if="medi.mimeType.includes('youtube')" ><b-badge variant="danger">YouTube</b-badge> {{ medi.title }}</p>
                                    <p v-else><b-badge variant="secondary">Video</b-badge> {{ medi.title }}</p>
                                </div>
                            </div>
                            <div v-if="medi.mimeType == 'application/pdf'">
                                <div style="float: left;">
                                    <img  class="card-img-top" style="width: auto; height: auto; max-width: calc(100% - 10px); max-height: 90%; position:absolute; left: 50%; top:50%; transform: translate3d(-50%,-50%, 0); float: left" src="img/icons/pdf.png" :title="medi.name" v-bind:alt="medi.alt">
                                </div>
                            </div>
                        </div>
                        <span v-if="medi.mimeType == 'application/pdf'" style="font-size: 10px;">{{medi.name}}</span>
                    </div>
                </div>
              <div slot="footer" style="float: right">
                  <b-button stacked size="sm" variant="primary" @click="saveMedia()" :disabled="selectedId == 0"><i class="fa fa-save"></i> Auswahl übernehmen</b-button>&nbsp;
                  <b-button size="sm" @click="closeMediaSelection()" variant="danger"><i class="fa fa-ban"></i>Abbrechen</b-button>
              </div>
            </div>

            <b-modal title="Medien hinzufügen" v-model="uploadModal" @ok="uploadMedia()">
                <input type="file" @change="fileInputSelected" multiple="multiple" ref="uploadFiles"><p>
                <div class="upload--box" v-cloak @drop.prevent="addFiles" @dragover.prevent>
                    <span>Hier Dateien per Drag+Drop hochladen</span>
                </div>
                <div v-if="fileCollection.length == 1">
                    <b-form-group>
                        <label for="mediaName">Name</label>
                        <b-form-input type="text" id="mediaName" v-model="uploadData.name" placeholder="Medienname"></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <label for="mediaAlt">Alt</label>
                        <b-form-input type="text" id="mediaAlt" v-model="uploadData.alt" placeholder="Alt-Text"></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <label for="mediaTitle">Title</label>
                        <b-form-input type="text" id="mediaTitle" v-model="uploadData.title" placeholder="Title-Text"></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <label for="mediaCaption">Caption</label>
                        <b-form-input type="text" id="mediaCaption" v-model="uploadData.caption" placeholder="Caption"></b-form-input>
                    </b-form-group>
                    <b-row>
                        <b-col md="6">
                            <b-form-group>
                                <label for="backgroundPosX">Background-Position-X</label>
                                <b-form-input style="width: 80%; float: left;" type="number" id="backgroundPosX" v-model="uploadData.backgroundPosX" value="50" placeholder="Background-Position-X"></b-form-input>
                                <b-input-group-append style="float: left; margin-top: 3px;">
                                    <b-input-group-text><i class="fa fa-percent"></i></b-input-group-text>
                                </b-input-group-append>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group>
                                <label for="backgroundPosY">Background-Position-Y</label>
                                <b-form-input style="width:80%; float: left;" type="number" id="backgroundPosY" v-model="uploadData.backgroundPosY" value="50" placeholder="Background-Position-Y"></b-form-input>
                                <b-input-group-append style="float: left; margin-top: 3px;">
                                    <b-input-group-text><i class="fa fa-percent"></i></b-input-group-text>
                                </b-input-group-append>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-modal>

        </div>
    </b-col>
</template>

<script>

import { mapGetters } from 'vuex'
import FolderTree from "./FolderTree";
import VueNotifications from 'vue-notifications'

const axios = require('axios')

export default {
    name: 'MediaSelection',
    components: {FolderTree},
    props: {
        show: false,
        type: '',
        mimeTypes: '',
        block: {
            default: false
        },
        title: {
            default: 'Medienauswahl'
        },
    },
    watch: {
      show: function(val) {
        this.screenPosition = window.pageYOffset
      }
    },
    data() {
        return {
            selectedId: 0,
            screenPosition: 0,
            selectedUid: 0,
            uploadModal: false,
            fileCollection: [],
            selectedMedia: null,
        }
    },
    computed: {
        ...mapGetters({
            media: 'media/getMedia',
            folders: 'media/getFolders',
        }),
    },
    methods:{
        closeMediaSelection: function() {
            this.selectedId = 0;
            this.selectedUid = 0;
            this.$emit('close')
        },
        selectMedia: function(media) {
            this.selectedId = media.id
            this.selectedUid = media.uid;
            this.selectedMedia = media;
        },
        saveMedia: function () {
            this.$emit('save', { id: this.selectedId, uid: this.selectedUid, image: this.selectedMedia.image, title: this.selectedMedia.title} )
            this.closeMediaSelection()
        },
        folderSelection(folder, element) {
            this.selectedFolder = folder
            this.$store.dispatch('media/loadMedia', { folderId: this.selectedFolder.id})
        },
        openFileUploadDialog() {
            if(this.selectedFolder != undefined && this.selectedFolder.id > 0){
                this.uploadModal = true,
                    this.uploadData = {
                        file: '',
                        name: '',
                        alt: '',
                        title: '',
                        caption: '',
                        backgroundPosX: 50,
                        backgroundPosY: 50,
                    }
            } else {
                this.notifyNoFolderSelected()
            }
        },
        fileInputSelected() {
            this.fileCollection = this.$refs.uploadFiles.files
            if(this.fileCollection.length == 1){
                if(this.uploadData.name == undefined || this.uploadData.name == '') {
                    this.uploadData.name = this.$refs.uploadFiles.files[0].name
                }
            } else {
                this.uploadMedia()
            }
        },
        addFiles(e){
            this.fileCollection = e.dataTransfer.files
            if(this.fileCollection.length == 1){
                if(this.uploadData.name == undefined || this.uploadData.name == '') {
                    this.uploadData.name = e.dataTransfer.files[0].name
                }
            } else {
                this.uploadMedia()
            }
        },
        uploadMedia() {
            for(let i = 0; i < this.fileCollection.length; i++){
                let formData = new FormData()
                formData.append('file', this.fileCollection[i])

                if(this.fileCollection.length == 1) {
                    formData.append('name', this.uploadData.name)
                    formData.append('alt', this.uploadData.alt)
                    formData.append('title', this.uploadData.title)
                    formData.append('caption', this.uploadData.caption)
                    formData.append('backgroundPosX', this.uploadData.backgroundPosX)
                    formData.append('backgroundPosY', this.uploadData.backgroundPosY)
                    formData.append('folderId', this.selectedFolder.id)
                } else {
                    formData.append('name', this.fileCollection[i].name)
                    formData.append('folderId', this.selectedFolder.id)
                }

                axios.post(this.$store.state.baseUrl + 'media', formData, { headers: { 'Content-Type': 'multipart/form-data', 'X-AUTH-TOKEN': this.$store.state.authObj.token }})
                    .then((response) => {
                        this.$store.dispatch('media/loadMedia', { folderId: this.selectedFolder.id})
                    })
                    .catch(error => {
                        if(error.response.status == 403){
                            commit('setAuthObj', { authObj: null }, { root: true })
                        } else {
                            // TODO: code me!
                        }
                    })
            }
            this.uploadModal = false
        },
    },
    mounted() {
        this.$store.dispatch('media/loadMedia', this.$route.params.id? this.$route.params.id : -1)
        this.$store.dispatch('media/loadFolders', { level: 0})
    },
    notifications: {
        notifyNoFolderSelected: {
            type: VueNotifications.types.error,
            title: 'Kein Ordner gewählt',
            message: 'Bitte wählen Sie zuerst einen Ordner'
        },
    }
}

</script>
